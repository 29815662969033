<template>
  <div class="text-start px-5 py-4">
    <form>
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <p>{{ $store.getters.companyName }}</p>
        <div class="d-flex my-2 overflow-auto align-items-center">
          <button
            class="btn-filled secondary-btn me-3 d-flex my-1 flex-shrink-0"
            type="button"
            @click.prevent="$router.back()"
          >
            <p>{{ $t("borrow.cancel") }}</p>
          </button>
          <button
            class="btn-filled primary-btn me-3 d-flex my-1 flex-shrink-0"
            @click.prevent="confirmBorrowRequestCreation"
          >
            <p>{{ $t("borrow.confirm_select") }}</p>
          </button>
        </div>
      </div>
      <h4 class="text-center mt-2 mb-5">
        {{ $t("borrow.create_borrow_order_title") }}
      </h4>
      <div class="container">
        <div class="row justify-content-center align-items-start">
          <div class="border-card col-12 col-lg-4">
            <p class="text-center mb-4">
              {{ $t("borrow.borrow_return_detail") }}
            </p>
            <div class="d-flex flex-column my-2">
              <p style="color: #7c7c7c;">
                {{ $t("borrow.borrow_start_date") }}*
              </p>
              <date-picker
                v-model="borrowStartDate"
                locale="en"
                mode="dateTime"
                is24hr
                :min-date="new Date()"
                :masks="masks"
                :minute-increment="60"
                is-required
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded w-100 focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    readonly
                    @click="togglePopover()"
                  />
                </template>
              </date-picker>
            </div>
            <div class="d-flex flex-column my-2">
              <p style="color: #7c7c7c;">{{ $t("borrow.borrow_end_date") }}*</p>
              <date-picker
                v-model="borrowEndDate"
                locale="en"
                mode="dateTime"
                is24hr
                :min-date="borrowStartDate"
                :validHours="hourIsValidEnd"
                :masks="masks"
                :minute-increment="1"
                is-required
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    class="px-2 py-1 border rounded w-100 focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    readonly
                    @click="togglePopover()"
                  />
                </template>
              </date-picker>
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">
                {{ $t("borrow.borrower") }}
              </p>
              <p>{{ $store.getters.getUserDetail.name }}</p>
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">{{ $t("borrow.note") }}</p>
              <input v-model.trim="note" class="form-control" type="text" />
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">Reference No.1</p>
              <input v-model.trim="refNo1" class="form-control" type="text" />
            </div>
            <div class="my-2">
              <p style="color: #7c7c7c;">Reference No.2</p>
              <input v-model.trim="refNo2" class="form-control" type="text" />
            </div>
          </div>
          <div class="border-card col-12 col-lg-4">
            <p class="text-center mb-4">{{ $t("borrow.borrow_list") }}</p>
            <p
              class="cursor-pointer d-inline-block my-2"
              style="color: #007AFE;"
              @click.prevent="$router.push({ name: 'select-borrow' })"
            >
              {{ $t("borrow.add_select_borrow") }}
            </p>
            <div class="d-flex justify-content-between my-2">
              <p style="color: #7c7c7c;">{{ $t("borrow.asset") }}</p>
              <p style="color: #7c7c7c;">
                {{ selectedAsset.length }} {{ $t("borrow.list") }}
              </p>
            </div>
            <asset-card
              v-for="asset in selectedAsset"
              :key="asset.asset_id"
              :assetId="asset.asset_id"
              :assetName="asset.name"
              :assetQR="asset.id_fixed_asset"
              :assetImageUrl="asset.asset_image"
              @action-click="removeSelectedAsset"
            ></asset-card>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import AssetCard from "../../components/BorrowReturn/AssetCard.vue";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";
import { mapGetters } from "vuex";
export default {
  components: {
    DatePicker,
    AssetCard,
  },
  data() {
    return {
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      borrowStartDate: moment()
        .add(1, "hour")
        .minute(0)
        .seconds(0)
        .toISOString(),
      borrowEndDate: moment()
        .add(2, "hour")
        .minute(0)
        .seconds(0)
        .toISOString(),
      note: null,
      refNo1: null,
      refNo2: null,
      selectedAsset: [],
    };
  },
  computed: {
    ...mapGetters({
      getBorrowOrderForm: "borrow/getBorrowOrderForm",
    }),
    minBorrowDateTime() {
      return moment()
        .add(1, "hour")
        .minute(0)
        .format();
    },
  },
  methods: {
    hourIsValidEnd(hour, dateParts) {
      if (moment(dateParts.date).isSame(this.borrowStartDate, "d")) {
        return hour > +moment(this.borrowStartDate).format("H");
      } else {
        return true;
      }
    },
    async confirmBorrowRequestCreation() {
      const asset_ids = this.selectedAsset?.map((asset) => asset.asset_id);
      const id_company = await this.$store.dispatch("getCompanyIdCookie");

      try {
        await axios.put(
          `${borrowUrl()}asset/request/company/id/${id_company}`,
          {
            start_datetime: this.borrowStartDate,
            end_datetime: this.borrowEndDate,
            borrow_note: this.note,
            ref_no_1: this.refNo1,
            ref_no_2: this.refNo2,
            asset_ids,
          },
          authHeader()
        );

        Swal.fire({
          icon: "success",
          title: this.$t("borrow.add_assets_success"),
          showConfirmButton: false,
          timer: 2000,
          onDestroy: () => {
            this.$router.push({
              name: "manage-order",
            });
          },
        });
      } catch (err) {
        console.log(err.response.data.error);
        Swal.fire({
          icon: "error",
          title: this.$t("borrow.add_assets_failed.title"),
          text: this.$t(`borrow.add_assets_failed.${err.response.data.error}`),
        });
      }
    },
    cacheBorrowOrderFormData() {
      this.$store.dispatch("borrow/setBorrowOrderForm", {
        borrowStartDateTime: this.borrowStartDate,
        borrowEndDateTime: this.borrowEndDate,
        note: this.note,
        refNo1: this.refNo1,
        refNo2: this.refNo2,
      });
      console.log("caching");
      console.log(this.borrowStartDate);
      console.log(this.borrowEndDate);
    },
    setSelectedAsset() {
      const selectedAssetList = [
        ...this.$store.getters["borrow/getBorrowAssetArray"],
      ];
      if (selectedAssetList.length > 0) {
        this.selectedAsset = selectedAssetList;
      }
    },
    removeSelectedAsset(assetId) {
      const index = this.selectedAsset.findIndex(
        (asset) => asset.asset_id === assetId
      );
      this.selectedAsset.splice(index, 1);
      this.$store.dispatch("borrow/removeSelectedAssetBorrow", assetId);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "select-borrow") {
      this.cacheBorrowOrderFormData();
      return next();
    }

    if (this.getBorrowOrderForm.cached) {
      this.$store.dispatch("borrow/clearBorrowOrderForm");
    }

    next();
  },
  beforeDestroy() {
    this.$store.dispatch("borrow/clearBorrowState");
  },
  created() {
    const { cached, data: storedBorrowOrderForm } = this.getBorrowOrderForm;
    if (cached) {
      this.borrowStartDate = storedBorrowOrderForm.borrowStartDateTime;
      this.borrowEndDate = storedBorrowOrderForm.borrowEndDateTime;
      this.note = storedBorrowOrderForm.note;
      this.refNo1 = storedBorrowOrderForm.refNo1;
      this.refNo2 = storedBorrowOrderForm.refNo2;
    }
    this.setSelectedAsset();
  },
};
</script>

<style lang="scss" scoped>
.btn-filled {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #fff;
    background-color: #007afe;
    border-color: #007afe;
  }
  &.secondary-btn {
    color: #fff;
    background-color: #818181;
    border-color: #818181;
  }
}

.border-card {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 30px 50px;
  margin: 0 15px;
  p {
    margin: 0;
  }
}
</style>
